import { useNavigate } from "react-router-dom";
import "./style.css";

const ItemCard = (props) => {
  const navigate = useNavigate();

  return (
    <div className="item-card">
      <section className="item-card-bg" />
      <section className="itm-img-parent">
        <img className="itm-img-icon" alt="" src="/itmimg@2x.png" />
        {/* <img className="vector-icon" loading="lazy" alt="" src="/vector.svg" /> */}
      </section>
      <section className="short-desc-parent">
        <div className="short-desc">{`short description of the product here. `}</div>
        <div className="frame-parent">
          <div className="price-parent">
            <div className="price">₦ 20,500</div>
            <div className="shipper-group">
              <div className="shippers-label">{`shipping: `}</div>
              <div className="shippers">foudhan express</div>
            </div>
          </div>
          <div className="fees-group">
            <div className="shipping-fee-label">shipping fee:</div>
            <div className="shipping-fee">free</div>
          </div>
        </div>
        <div className="button-instance">
          <button
            onClick={() => {
              navigate("/product-desc");
            }}
            className="button"
          >
            <div className="text">Add to cart</div>
          </button>
        </div>
      </section>
    </div>
  );
};

export default ItemCard;
