// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyDYuHK6l2791qG8_dRXGZuAiPAmNaOuY80",
  authDomain: "foudhan-1393a.firebaseapp.com",
  databaseURL: "https://foudhan-1393a-default-rtdb.firebaseio.com",
  projectId: "foudhan-1393a",
  storageBucket: "foudhan-1393a.appspot.com",
  messagingSenderId: "889422743432",
  appId: "1:889422743432:web:01b0a9047842c01f8bdc3d",
  measurementId: "G-KHRB2R41F3",
};

firebase.initializeApp(firebaseConfig);
const database = firebase.database(); // Get the database reference

export { firebase, database };
