import { MDBCard, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useContext, useEffect, useState } from "react";
import { ServiceComContext } from "../dashboard";
import { useNavigate } from "react-router-dom";
import ItemCard from "./item-card";
import request from "superagent";

const ItemsMenu = () => {
  const [products, setProducts] = useState([]);
  const handleFetchData = async () => {
    const response = await request
      .get("https://api.foudhan.com/store/products.php")
      .type("application/json")
      .then((response) => {
        console.log("PRODUCTS", response.body.data);

        setProducts(response.body.data);
        // setItemName("");
        // setPrice(0);
        // setProductDesc("");
      });
  };

  useEffect(handleFetchData, []);

  return (
    <div>
      <MDBContainer>
        <MDBRow>
          {products.map((product, i) => {
            return (
              <MDBCol className="d-flex justify-content-center align-items-center">
                <ItemCard product={product} />
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default ItemsMenu;
