import React from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBInputGroup,
  MDBBtn,
  MDBNavbarBrand,
} from "mdb-react-ui-kit";

export default function MainBar(props) {
  return (
    <MDBNavbar expand="lg" light bgColor="light">
      <MDBContainer fluid>
        <MDBNavbarNav className="d-flex flex-row">
          <MDBNavbarItem className="me-3 mr-4 me-lg-0">
            <MDBNavbarLink href="#">
              <MDBIcon onclick={props.showSideMenu(true)} fas icon="bars" />
            </MDBNavbarLink>
          </MDBNavbarItem>

          <MDBNavbarBrand className="ml-4">Foudhan store</MDBNavbarBrand>

          <MDBNavbarItem className="me-3 me-lg-0">
            <MDBNavbarLink href="#">
              <MDBIcon style={{ color: "#360101" }} fas icon="shopping-cart" />
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem className="me-3 me-lg-0">
            <MDBNavbarLink href="#">
              <MDBIcon style={{ color: "#360101" }} fas icon="shopping-bag" />
            </MDBNavbarLink>
          </MDBNavbarItem>
        </MDBNavbarNav>

        <MDBInputGroup
          tag="form"
          className="d-flex mb-3"
          style={{ width: "50%" }}
        >
          <input
            className="form-control"
            placeholder="search for product"
            aria-label="Search"
            type="Search"
          />
          <MDBBtn style={{ backgroundColor: "#360101", color: "white" }}>
            Search
          </MDBBtn>
        </MDBInputGroup>
      </MDBContainer>
    </MDBNavbar>
  );
}
