import React from "react";
import "./App.css";
import { Routes, Route, Router, Navigate, Outlet } from "react-router-dom";
import Login from "./components/Login";
import Landhome from "./components/landhome";
import { ProSidebarProvider } from "react-pro-sidebar";
import Registration from "./components/registration";
import ProtectedRoute from "./components/ProtectedRoute";
import DashBoard from "./components/dashboard";
import PasswordReset from "./components/passwordReset";

import BuyDataCardComponent from "./components/buyDataCard";
import BuyAirtimeComponent from "./components/buyAirtimeComponent";
import AboutUs from "./components/aboutUs";
import Support from "./components/support";
import OurApp from "./components/aboutOurAndroidApp";
import TransactionsComponent from "./components/transactionsComponent";
import BuyDataComponent from "./components/buyDataComponent";
import AirtimeToCash from "./components/airtimeToCash";
import BuyElectricSubscription from "./components/buyElectricSub";
import TvSubcription from "./components/tvSubcription";
import BulkSMSComponent from "./components/bulkSms";
import ResultChecking from "./components/resultCheckinPin";
import MainDashboard from "./components/MainDashboard";
import SettingsMenu from "./components/dashboardMenu";
import Settings from "./components/settings";
import AcademyRegistration from "./components/academyRegistration";
import Pricing from "./components/pricing";
import PosOrder from "./components/posOrder";
import StoreHome from "./components/store/home";
import BussinessManagement from "./components/store/merchant/main";
import ProductsManagement from "./components/store/merchant/products";
import ProductDescription from "./components/store/product-description";

const App = () => (
  <div>
    <ProSidebarProvider>
      <Routes>
        <Route path="/" element={<Landhome />} />
        <Route path="store" element={<StoreHome />} />
        <Route path="product-desc" element={<ProductDescription />} />
        <Route path="login" element={<Login />} />
        <Route path="registration" element={<Registration />} />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <DashBoard />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<MainDashboard />} />
          <Route path="merchant" element={<BussinessManagement />} />
          <Route path="products" element={<ProductsManagement />} />
          <Route path="data" element={<BuyDataComponent />} />
          <Route path="airtime" element={<BuyAirtimeComponent />} />
          <Route path="data-card" element={<BuyDataCardComponent />} />
          <Route path="airtime-to-cash" element={<AirtimeToCash />} />
          <Route path="electric-sub" element={<BuyElectricSubscription />} />
          <Route path="tv-sub" element={<TvSubcription />} />
          <Route path="bulk-sms" element={<BulkSMSComponent />} />
          <Route path="school-card" element={<ResultChecking />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="contact" element={<Support />} />
          <Route path="transactions" element={<TransactionsComponent />} />
          <Route path="settings" element={<Settings />} />
          <Route path="pos-order" element={<PosOrder />} />
          <Route path="ourapp" element={<OurApp />} />
        </Route>
        <Route path="passreset" element={<PasswordReset />} />
        <Route path="ourapp" element={<OurApp />} />
      </Routes>
    </ProSidebarProvider>
  </div>
);

export default App;
