import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { firebase } from "../services/config";
import "firebase/compat/database";
import "firebase/compat/storage";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
  MDBCard,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBCardImage,
  MDBBtn,
} from "mdb-react-ui-kit";
import { Alert, Collapse, Stack, Snackbar } from "@mui/material";
import "../css/style.css";
import logo from "../pictures/am_data.png";
import TextInput from "./textField";
import SuccessAlert from "./alert";
import Spinner from "./spinner";

const database = firebase.database();

export default function AcademyRegistration() {
  //   const monifyBaseUrl = "https://api.monnify.com";
  //   const contactContract = "451332972854";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [progress, setProgress] = useState(false);
  const [regSuccess, setRegSuccess] = useState(false);
  const [validate, setValidate] = useState(false);
  const [netCon, setNetCon] = useState(false); // network connection checker
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSubmit = async (event) => {
    setProgress(true);

    handleVerification();

    let uniqueId = email.split("@");
    uniqueId = uniqueId[0];

    if (navigator.onLine) {
      fetch("https://www.google.com/", { mode: "no-cors" }).then(() => {
        try {
          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Push Function
              let tref = database.ref("/Customers/" + uniqueId + "/userInfo");
              tref
                .set({
                  firstName: toSentenceCase(firstName),
                  surname: toSentenceCase(surname),
                  phoneNumber: phoneNumber,
                  email: email,
                  pin: pin,
                })
                .then(() => {
                  let balRef = database.ref(
                    "/Customers/" + uniqueId + "/accountBalance"
                  );
                  balRef.set({ value: 0.0 }).then(() => {
                    // monify dedicated account
                    // userDedicatedAccount(generateAuthKey()).then(() => {
                    //navigating to success component
                    setRegSuccess(true);
                    // });
                  });
                });
            })
            .catch((error) => {
              console.log(error);
              setProgress(false);
            });
        } catch (error) {
          console.log(error);
          alert(error);
          setProgress(false);
        }
      });
    } else {
      setProgress(false);
      setNetCon(true);
    }
  };

  function toSentenceCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const handleVerification = () => {
    setValidate(true);
    if (firstName === "") return;
    if (surname === "") return;
    if (phoneNumber === "") return;
    if (email === "") return;
    if (password === "") return;
    // if (pin === "") return;
    // if (pin !== confirmPin) return;
    if (password !== confirmPassword) return;

    return true;
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <div
      className="login-bg"
      style={{ height: "100vh", overflowY: "scroll", paddingTop: "200px" }}
    >
      <div style={{ marginTop: "20px" }}>
        <MDBContainer className="d-flex align-items-center justify-content-center">
          <MDBCard className="shadow-2 d-flex align-items-center m-4 p-4">
            <MDBCard
              onClick={handleLogoClick}
              className="shadow-2"
              style={{ cursor: "pointer", width: "100px" }}
            >
              <MDBCardImage position="top" src={logo}></MDBCardImage>
            </MDBCard>

            <MDBRow className="m-2">
              <MDBCol>
                <div class="text-center m-3">
                  <h3>New Student</h3>
                </div>
              </MDBCol>
            </MDBRow>
            {
              <ErrorNotifier
                show={netCon}
                message="No internet connection"
                onErrClose={() => {
                  setNetCon(false);
                }}
              />
            }
            <MDBRow>
              <MDBCol>
                <TextInput
                  tValue={setFirstName}
                  tType="text"
                  tLabel="First Name"
                  validate={validate}
                />

                <TextInput
                  tValue={setSurname}
                  tType="text"
                  tLabel="Last Name"
                  validate={validate}
                />

                <TextInput
                  tValue={setEmail}
                  tType="text"
                  tLabel="Email"
                  validate={validate}
                />

                <TextInput
                  tValue={setPhoneNumber}
                  tType="text"
                  tLabel="Phone number "
                  validate={validate}
                />

                <TextInput
                  tValue={setPassword}
                  tType="password"
                  tLabel="password"
                  validate={validate}
                />

                <TextInput
                  tValue={setConfirmPassword}
                  tType="password"
                  tLabel="confirm password"
                  validate={validate}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow className="m-2">
              <MDBCol>
                <Collapse in={progress}>
                  <Stack style={{ color: "grey.500" }} spacing={0.5}>
                    <Spinner />
                  </Stack>
                </Collapse>

                <MDBBtn
                  className="mt-1 button"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={progress}
                >
                  REGISTER
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </MDBContainer>
        <SuccessAlert showMe={regSuccess} nav="/login" />
      </div>
    </div>
  );
}

export const ErrorNotifier = (props) => {
  return (
    <div>
      <Snackbar
        open={props.show}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={6000}
        onClose={props.onErrClose}
      >
        <Alert style={{ textAlign: "center" }} severity="error" sx={4}>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
