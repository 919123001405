import { useContext, useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBBtn,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import { useRef } from "react";
import "firebase/compat/storage";
import "firebase/compat/database";
import { Alert, Snackbar, TextField } from "@mui/material";
import PinModel from "./PinModel";
import { ServiceComContext } from "./dashboard";
import moniepointImg from "../pictures/moniepoint_lay.png";

const PosOrder = () => {
  const [init, setInit] = useState(false);
  const refElectric = useRef(null);

  const items = [
    { name: "POS", price: 21000, discount: 500, id: 1 },
    { name: "ATM", price: 1500, discount: 200, id: 2 },
  ];
  const { uniqueId, userAgent } = useContext(ServiceComContext);
  const userId = uniqueId;
  const [item, setItem] = useState(items[0].name);
  const [itemPrice, setItemPrice] = useState(items[0].price);
  const [quantity, setQuantity] = useState(1);
  const [amount, setAmount] = useState(items[0].price);
  const [discount, setDiscount] = useState(items[0].discount);
  const [itemId, setItemId] = useState(items[0].id);

  const [submit, setSubmit] = useState(false);

  const [order, setOrder] = useState({});
  const [validity, setvalidity] = useState(false);
  const { smsSender } = useContext(ServiceComContext);

  useEffect(() => {
    if (!init) {
      refElectric.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      setInit(true);
    }
  }, []);

  const validateInput = () => {
    // if (discoID === 0) return false;
    // if (meterNumber === "") return false;
    // if (meterType === "") return false;
    // if (amount === 0) return false;

    return true;
  };

  return (
    <div
      className="d-flex flex-column align-items-center mt-4"
      ref={refElectric}
      style={{ padding: "30px" }}
    >
      <MDBCardBody>
        <MDBCardText>
          <h3>POS/ATM ORDER</h3>
        </MDBCardText>
      </MDBCardBody>

      <MDBCard className="center service-container shadow-2 m-2">
        <img
          src={moniepointImg}
          alt="moniepoint image"
          style={{ width: "60%", height: "32%" }}
        />

        <h3 style={{ padding: "10px", fontSize: "3em" }}>
          {item} PRICE: {itemPrice}
        </h3>
      </MDBCard>

      <MDBCard className="center service-container shadow-2 m-2">
        <MDBDropdown>
          <MDBDropdownToggle color="secondary">Item Type</MDBDropdownToggle>
          <MDBDropdownMenu dark>
            {items.map((e, index) => {
              return (
                <MDBDropdownItem
                  onClick={() => {
                    setItem(e.name);
                    setItemPrice(e.price);
                    setItemId(e.id);
                    let d = e.discount * quantity;
                    setDiscount(d);
                    setAmount(e.price * quantity - d);
                  }}
                  link
                  childTag="button"
                >
                  {e.name}
                </MDBDropdownItem>
              );
            })}
          </MDBDropdownMenu>
        </MDBDropdown>
        <MDBCardBody>
          <MDBCardText>{item}</MDBCardText>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="shadow-2 service-container ">
        <TextField
          className="m-3 w-2"
          label="Qauntity"
          type="number"
          InputProps={{
            inputProps: {
              style: { textAlign: "center" },
            },
          }}
          onBlur={(e) => {
            let v = e.target.value;

            items.map((e) => {
              if (e.name === item) {
                setQuantity(v);
                let d = e.discount * v;
                let a = e.price * v - d;
                setDiscount(d);
                setAmount(a);
              }
            });
          }}
        />
      </MDBCard>
      <MDBCard className="shadow-2 service-container ">
        <TextField
          className="m-3 w-2"
          label="discount"
          type="text"
          disabled
          value={discount}
          InputProps={{
            inputProps: {
              style: { textAlign: "center" },
            },
          }}
        />
      </MDBCard>
      <MDBCard className="shadow-2 service-container ">
        <TextField
          className="m-3 w-2"
          label="Amount to pay"
          type="text"
          disabled
          value={amount}
          InputProps={{
            inputProps: {
              style: { textAlign: "center" },
            },
          }}
        />
      </MDBCard>

      <MDBBtn
        onClick={() => {
          if (validateInput()) {
            const uniqueOrderID = Math.floor(Date.now() / 1000);
            const orderReg = {
              quantity: quantity,
              amount: Number(amount),
              itemId: itemId,
              userId: userId,
              userAgent: userAgent,
              orderId: "" + uniqueOrderID,
              service: "pos",
            };

            console.log(orderReg);

            let confirmMsg =
              "You are about to order for Moniepoint " + item + " at " + amount;
            setSubmit(true);
            setOrder({
              serviceType: "pos",
              order: orderReg,
              confirmMsg: confirmMsg,
            });
          } else {
            setvalidity(true);
          }
        }}
        className="m-2 button"
        size="lg"
      >
        ORDER NOW
      </MDBBtn>
      <Snackbar
        open={validity}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={6000}
        onClose={() => {
          setvalidity(false);
        }}
      >
        <Alert severity="error" sx={4}>
          Invalid input please check.
        </Alert>
      </Snackbar>
      {/* <PinModel order={order} showMe={submit} resetModel={setSubmit} /> */}
    </div>
  );
};

export default PosOrder;
