import { useState, useEffect } from "react";
import { TextField, Snackbar, Alert } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import request from "superagent";

const AddProducts = ({ userId }) => {
  const [itemName, setItemName] = useState("");
  const [price, setPrice] = useState(0);
  const [shipper, setShipper] = useState("Foudhan Express");
  const [shippingFee, setShippingFee] = useState(50);
  const [deliveryTime, setDeliveryTime] = useState("Within 4 hours");
  const [delivery, setDelivery] = useState("House Delivery");
  const [productDesc, setProductDesc] = useState("");
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    console.log("USER ID", userId);
  }, [userId]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const validateForm = () => {
    if (!itemName || !price || !productDesc) {
      setError("All fields are required.");
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const handleAddProduct = async () => {
    if (!validateForm()) return;

    Swal.fire({
      title: "Processing...",
      html: "Please wait while we process your transaction",
      allowOutsideClick: false,
      allowEscapeKey: false,
      timerProgressBar: false,
      didOpen: async () => {
        Swal.showLoading();

        const req = {
          ItemName: itemName,
          Price: price,
          Shipper: shipper,
          ShippingFee: shippingFee,
          DeliveryTime: deliveryTime,
          Delivery: delivery,
          ProductDescription: productDesc,
          UserId: userId,
        };

        try {
          const response = await request
            .post("https://api.foudhan.com/store/products.php")
            .type("application/json")
            .send(req);

          Swal.fire({
            title: "Success",
            text: "Product created successfully",
            icon: "success",
          }).then(() => {
            setItemName("");
            setPrice(0);
            setProductDesc("");
          });
        } catch (err) {
          let errorMsg = "An error occurred while processing your request.";

          if (err.response && err.response.status === 400) {
            errorMsg = err.response.text;
          }

          Swal.fire({
            title: "Error",
            text: errorMsg,
            icon: "error",
          });
        }
      },
    });
  };

  return (
    <div>
      <h3 className="center">Create Product</h3>
      <TextField
        className="my-2 w-100"
        label="Item Name"
        value={itemName}
        type="text"
        onChange={(e) => setItemName(e.target.value)}
      />
      <TextField
        className="my-2 w-100"
        label="Price"
        value={price}
        type="number"
        onChange={(e) => setPrice(e.target.value)}
      />
      <TextField
        className="my-2 w-100"
        label="Product Description"
        value={productDesc}
        type="text"
        onChange={(e) => setProductDesc(e.target.value)}
      />
      <TextField
        className="my-2 w-100"
        label="Shipper"
        value={shipper}
        type="text"
        onChange={(e) => setShipper(e.target.value)}
        disabled
      />
      <TextField
        className="my-2 w-100"
        label="Shipping Fee"
        value={shippingFee}
        type="number"
        onChange={(e) => setShippingFee(e.target.value)}
        disabled
      />
      <TextField
        className="my-2 w-100"
        label="Delivery Time"
        value={deliveryTime}
        type="text"
        onChange={(e) => setDeliveryTime(e.target.value)}
        disabled
      />
      <TextField
        className="my-2 w-100"
        label="Delivery"
        value={delivery}
        type="text"
        onChange={(e) => setDelivery(e.target.value)}
        disabled
      />

      <MDBBtn onClick={handleAddProduct}>Add</MDBBtn>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddProducts;
