import { useContext, useEffect, useState } from "react";
import { MDBCard, MDBCardBody, MDBCardText, MDBBtn } from "mdb-react-ui-kit";
import { useRef } from "react";
import "firebase/compat/storage";
import "firebase/compat/database";
import { Alert, Snackbar } from "@mui/material";
import { ServiceComContext } from "../../dashboard";

const BussinessManagement = () => {
  const [init, setInit] = useState(false);
  const refElectric = useRef(null);

  const items = [
    { name: "POS", price: 21000, discount: 500, id: 1 },
    { name: "ATM", price: 1500, discount: 200, id: 2 },
  ];
  const { uniqueId, userAgent } = useContext(ServiceComContext);
  const userId = uniqueId;
  const [item, setItem] = useState(items[0].name);
  const [itemPrice, setItemPrice] = useState(items[0].price);
  const [quantity, setQuantity] = useState(1);
  const [amount, setAmount] = useState(items[0].price);
  const [discount, setDiscount] = useState(items[0].discount);
  const [itemId, setItemId] = useState(items[0].id);

  const [submit, setSubmit] = useState(false);

  const [order, setOrder] = useState({});
  const [validity, setvalidity] = useState(false);
  const { smsSender } = useContext(ServiceComContext);

  useEffect(() => {
    if (!init) {
      refElectric.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      setInit(true);
    }
  }, []);

  const validateInput = () => {
    // if (discoID === 0) return false;
    // if (meterNumber === "") return false;
    // if (meterType === "") return false;
    // if (amount === 0) return false;

    return true;
  };

  return (
    <div
      className="d-flex flex-column align-items-center mt-4"
      ref={refElectric}
      style={{ padding: "30px" }}
    >
      <MDBCardBody>
        <MDBCardText>
          <h3>Bussiness Management</h3>
        </MDBCardText>
      </MDBCardBody>

      <MDBCard className="center service-container shadow-2 m-2">
        <h3 style={{ padding: "10px", fontSize: "3em" }}>
          {item} PRICE: {itemPrice}
        </h3>
      </MDBCard>

      <MDBCard className="center service-container shadow-2 m-2"></MDBCard>

      <MDBBtn
        onClick={() => {
          if (validateInput()) {
            const uniqueOrderID = Math.floor(Date.now() / 1000);
            const orderReg = {
              quantity: quantity,
              amount: Number(amount),
              itemId: itemId,
              userId: userId,
              userAgent: userAgent,
              orderId: "" + uniqueOrderID,
              service: "pos",
            };

            console.log(orderReg);

            let confirmMsg =
              "You are about to order for Moniepoint " + item + " at " + amount;
            setSubmit(true);
            setOrder({
              serviceType: "pos",
              order: orderReg,
              confirmMsg: confirmMsg,
            });
          } else {
            setvalidity(true);
          }
        }}
        className="m-2 button"
        size="lg"
      >
        ORDER NOW
      </MDBBtn>
      <Snackbar
        open={validity}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={6000}
        onClose={() => {
          setvalidity(false);
        }}
      >
        <Alert severity="error" sx={4}>
          Invalid input please check.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BussinessManagement;
