import { useContext, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBBtn,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { ServiceComContext } from "../../dashboard";
import AddProducts from "./add-products";

const ProductsManagement = () => {
  const [nav, setNav] = useState("");
  const { uniqueId } = useContext(ServiceComContext);
  const userId = uniqueId;

  return (
    <div
      className="d-flex flex-column align-items-center mt-4"
      style={{ padding: "30px" }}
    >
      {nav === "add" && <AddProducts userId={userId} />}
      {nav === "" && (
        <div>
          <MDBCardBody>
            <MDBCardText>
              <h3>Products Management</h3>
            </MDBCardText>
          </MDBCardBody>
          <MDBCard className="center service-container shadow-2 m-2">
            <MDBRow className="justify-content-center">
              <MDBCol
                xs="12"
                sm="6"
                md="4"
                lg="3"
                className="d-flex justify-content-center"
              >
                <MDBBtn
                  onClick={() => setNav("add")}
                  className="m-1 w-100 button"
                >
                  Add Product
                </MDBBtn>
              </MDBCol>
              <MDBCol
                xs="12"
                sm="6"
                md="4"
                lg="3"
                className="d-flex justify-content-center"
              >
                <MDBBtn className="m-1 w-100">Update Product</MDBBtn>
              </MDBCol>
              <MDBCol
                xs="12"
                sm="6"
                md="4"
                lg="3"
                className="d-flex justify-content-center"
              >
                <MDBBtn className="m-1 w-100 button">Remove Product</MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </div>
      )}
    </div>
  );
};

export default ProductsManagement;
