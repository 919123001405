import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import vailsPic from "../../../pictures/store/vails.jpg";
import { Card, FormControlLabel } from "@mui/material";
// import { PDFDocument } from "pdf-lib";
// import { saveAs } from "file-saver";
import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import request from "superagent";
import Swal from "sweetalert2";
// import { Toast } from "../errorNotifier.js";
// import { loader } from "../LoadingSpinner.js";
// import Atm from "../atm.js";
import axios from "axios";

function ProductDescription(props) {
  // let navigate = useNavigate();
  // let location = useLocation();
  const [fund, setFund] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  return (
    <div>
      <MDBContainer className="d-flex flex-column align-items-center justify-content-center w-100">
        <MDBRow className="w-100">
          <MDBCol md={8}>
            <DescriptionCard setFunds={setFund} />
          </MDBCol>

          <MDBCol md={4}>
            <ShippingDetailCard />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

const DescriptionCard = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [walletBalance, setWalletBalance] = useState(0);

  return (
    <div>
      <MDBCol className="d-flex flex-column align-items-center justify-content-center">
        <Card sx={{ maxWidth: 700 }} className="p-4 w-100">
          <MDBRow className="mb-2 w-100">
            <MDBCol className="text-center">
              <img
                className="w-100"
                src={vailsPic}
                style={{ height: "400px" }}
                alt="product image"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <h4>Product name</h4>
            <div>
              <span style={{ fontWeight: 900, fontSize: "22px" }}>₦ 8700</span>
            </div>
            <p>
              <b>Product details Description:</b>
              Famicare Solar Charge Controller 600A/12v/24v PWM/600A 12V/24V
              Dual USB Solar Panel Controller Charge Regulator.
              <br />
              <br />
              <b>Features:</b> Solar charge controller comes with a big LCD
              display that can clearly indicate the status and data, it can be
              conveniently switched modes and parameter configuration. Solar
              panel suitable for solar system, solar street lamp, smart home,
              solar light box, intelligent irrigation,camping lantern etc. The
              solar regulator is only suitable for lead acid batteries:OPEN, , ,
              not for nickel hydride, lithium, Liions, or other batteries.
              Short-circuit protection,open-circuit protection,reverse
              protection,over-load protection, more safty for using, suitable
              for home, industrial, commercial etc. This controller can only use
              photovoltaic panel as the source, do not use or other supply as
              the source.
            </p>
          </MDBRow>
          <MDBRow className="w-100">
            <MDBCol>
              <MDBBtn
                style={{ background: "#05321e" }}
                onClick={() => {
                  props.setFunds(true);
                }}
                className="m-2 p-2 w-50 button"
              >
                Add to cart
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </Card>
      </MDBCol>
    </div>
  );
};

const ShippingDetailCard = () => {
  return (
    <div>
      <MDBCol className="d-flex flex-column align-items-center justify-content-center">
        <Card sx={{ maxWidth: 700 }} className="p-4 w-100">
          <div className="m-4"></div>
          <div className="reg-captions">Shipping</div>

          <MDBRow className="mb-2 w-100">
            <MDBCol className="text-center"></MDBCol>
          </MDBRow>
          <MDBRow className="w-100">
            <MDBCol>
              <MDBBtn
                style={{ background: "#05321e" }}
                className="m-2 p-2 w-50 button"
              >
                Add to cart
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </Card>
      </MDBCol>
    </div>
  );
};

export default ProductDescription;
